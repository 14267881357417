import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import ReactSelectStyle from '../../../components/ui/ReactSelectStyle';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom';
import DateService from '../../../services/DateService';
import Loader from '../../../components/ui/Loader/Loader';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';

class InputDealSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state                  =   {
            selectedCustomer               :   null,
            timer                       :   false,
            defaultOptions              :   [],
            deal_listing                :   []
            
        };
        this.cancelToken            =   null;
        this.apiTimeout             =   null;
        this.dealListUrl      =   INVENTORY_API_BASE_URL_2 + '/deal/list';
    }
    
    initParams                          =   (props)  =>  {
        this.setState({
            params                      :   {
                search_customer_ids        :   props.search_customer_id && Array.isArray(props.search_customer_id) ? props.search_customer_id : (props.search_customer_id ? [props.search_customer_id] : []),
                search_customer_project_ids        :   props.search_customer_project_id && Array.isArray(props.search_customer_project_id) ? props.search_customer_project_id : (props.search_customer_project_id ? [props.search_customer_project_id] : []),
            }
        }, () => {
            if(this.props.loadDefaultOptions == true) {
                this.getDefaultOptions();
            }
        });
    }
    
    componentDidMount() {
        this.initParams(this.props);
        this.dealListingModal                =   new Modal(document.getElementById('dealListingModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.search_customer_id !== this.props.search_customer_id || nextProps.search_customer_project_id !== this.props.search_customer_project_id ) {
            this.initParams(nextProps);
        }
    }

    onInputChange                   =   (selectedOption)  =>  {
        this.props.changeEvent(selectedOption);
        this.setState({tempSelectedDeal : null,selectedDeal : null})
    }
    
    displayStringSearch             =   (deals)  =>  {
        return deals.map(deal => {
            return {
                label               :   deal.header,
                value               :   deal.id,
                display_label       :   deal.header,
                deal            :   deal
            };
        });
    };
    
    loadingOptions                  =   (inputText,callback)  =>  {
        if(inputText.length > 2) {
            clearTimeout(this.apiTimeout)
            if (this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken = axios.CancelToken.source();

            let params          = {...this.state.params, search_by_text: inputText, per_page: 50};
            
            this.apiTimeout  = setTimeout(() => {this.getLoadingOptions(params,callback)},1000);
        }
    }

    getLoadingOptions         =        (params,callback)            =>         {
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: params,
            url: `${INVENTORY_API_BASE_URL_2}/deal/list`,
            cancelToken: this.cancelToken.token
        })
        .then((response) => {
            callback(this.displayStringSearch(response.data.data))
        }).catch((err) => {
            if (axios.isCancel(err)) {
              
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        });
    }

    getDefaultOptions               =       ()              =>          {
        this.setState({defaultOptions : []});
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: this.state.params,
            url: `${INVENTORY_API_BASE_URL_2}/deal/list`,
        })
        .then((response) => {
            this.setState({
                defaultOptions:     this.displayStringSearch(response.data.data)
            });
        }).catch((err) => {
            if (axios.isCancel(err)) {
               
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        });
    }

    dealListingModalInit                   =   ()  =>  {
        this.dealListingModal.show();
        //if(!this.state.listingLoaded){
           this.loadListingTblData(1)
        //}
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.params};
        HttpAPICall.withAthorization('GET',  this.dealListUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                deal_listing                : respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount   : respData.total,
                listingLoaded       :   true
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    handleSelectedDeal() {
        if (this.state.tempSelectedDeal) {
            const selectedDeal = this.state.deal_listing.find(deal => deal.id === this.state.tempSelectedDeal);
            this.setState({ selectedDeal }, () => {
                this.props.changeDeal({ deal_id: selectedDeal.id, ...selectedDeal,
                    label               :   selectedDeal.header,
                    value               :   selectedDeal.id,
                    display_label       :   selectedDeal.header,
                    deal                :   selectedDeal
                });
                this.dealListingModal.hide();
            });
        } else {
           
        }
    }

    handleCancel(type='cancel') {
        if(type !== 'cancel'){
            this.setState({ tempSelectedDeal: null,selectedDeal : null },() => {
               
            });
        }else{
            this.setState({ tempSelectedDeal: this.state.tempSelectedDeal ? this.state.tempSelectedDeal : null})
        }
    }

    viewDealListingModalJsx               =   ()  =>  {
        return (
            <div className="modal fade" id="dealListingModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="CustomerDetailsModalLabel">Deal List</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-bordered table-fixed bg-white table-sm">
                                <thead className="table-secondary">
                                <tr>
                                    <th scope="col" rowSpan="2" style={{width:"5%"}} className="text-center">S.No</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"8%"}}>Creation Date</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"20%"}}>Header</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"12%"}} >Deal Type</th>
                                    <th scope="col" rowSpan="2" style={{width:"15%"}} >Customer</th>
                                    <th scope="col" rowSpan="2" style={{width:"13%"}} >Last Updated</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"8%"}} >Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.listing_loading
                                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                        : (this.state.deal_listing && this.state.deal_listing.length > 0
                                            ? (this.state.deal_listing.map((deal, index) => {
                                                return (
                                                    <tr key={deal.id}>
                                                        <td className="text-center">
                                                            <input
                                                                type="radio"
                                                                name="selectedDeal"
                                                                value={deal.id}
                                                                checked={this.state.tempSelectedDeal === deal.id}
                                                                onChange={() => this.setState({ tempSelectedDeal: deal.id })}
                                                            />
                                                        </td>
                                                        <td>{deal.created_at ? DateService.dateTimeFormat(deal.created_at,'DD-MMM-YYYY') : ''}</td>
                                                        <td><Link  role="button">{deal.header}</Link></td>
                                                        <td>{deal.deal_type ? deal.deal_type.name : "-"}</td>

                                                        <td className="text-capitalize">
                                                            {deal.customer && <span>{deal.customer.name}</span>}
                                                        </td>
                                                        <td>{deal.updated_at ? DateService.dateTimeFormat(deal.updated_at,'DD-MMM-YYYY') : ''} <br/>
                                                            {deal.latest_remark ? <small>{deal.latest_remark?.remark}</small> : null}
                                                        </td>
                                                        <td  className="text-center"><Status color={deal.status.color}>{deal.status.name}</Status></td>
                                                    </tr>
                                                );
                                            }))
                                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                        )
                                    }
                                </tbody>
                            </table>
                            <DisplayListPagination
                                meta={this.state.listingMeta}
                                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => this.handleCancel('cancel')}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.handleSelectedDeal()}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    
    render() {
        let isDisabled = this.props.disabled ? this.props.disabled : false;
        return (<div className='row'>
            <div className={this.props.showSearch ? 'col-sm-10' : 'col-sm-12'}>
                <AsyncSelect
                    styles={ReactSelectStyle(this.props)}
                    menuPlacement="bottom"
                    placeholder={this.props.placeholder || "Search Deals"}
                    loadingMessage={(input) => {
                        if (input.inputValue && input.inputValue.length <= 2) {
                            return 'Please enter 3 or more characters';
                        } else {
                            return 'Searching...';
                        }
                    }}
                    noOptionsMessage={(input) => {
                        if (input.inputValue.length == 0) {
                            return 'Please enter 3 or more characters';
                        } else {
                            return 'No Deals found';
                        }
                    }}
                    minimumInput={5}
                    complete={true}
                    onChange={this.onInputChange}
                    isClearable={true}
                    loadOptions={this.loadingOptions}
                    value={this.props.value}
                    isMulti={this.props.isMulti || false}
                    required={true}
                    isDisabled={this.props.disabled || false}
                    defaultOptions={this.props.loadDefaultOptions == true ? this.state.defaultOptions : null}
                />
                <input
                    name=''
                    tabIndex={-1}
                    required={this.props.required || false}
                    value={this.props.isMulti ? (this.props.value.length > 0 ? this.props.value[0].label : [].join(' ')) : (this.props.value ? this.props.value.label : '')}
                    onChange={() => { }}
                    style={{
                        opacity: 0,
                        width: 0,
                        height: 0,
                        padding: 0,
                        margin: 0,
                        display: 'table'
                    }}
                />
            </div>
           {this.props.showSearch && <div className='col-sm-2'>
                {!isDisabled && <button type="button" className="btn btn-secondary" onClick={() => this.dealListingModalInit()}>
                    <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                </button>}
                
            </div>}
            {this.viewDealListingModalJsx()}
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(InputDealSearch);