import React from 'react';
import { Helmet } from 'react-helmet';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import "../MasterData.css";
import SalesTeam from './SalesTeam';
import QuotationConfiguration from './quotationConfiguration/QuotationConfiguration';
import DealsConfiguration from './deals/DealsConfiguration';
import ProjectConfiguration from './customerProject/ProjectConfiguration';

class ConfigurationSales extends React.Component {

    constructor(props) {
        super(props);
        this.state                  =   {
            configScreen                :   "deals",
            add                         :   false,
            itemWhConfigDownloading     :   false,
        }
        this.handleClose            =   this.handleClose.bind(this);
        this.itemWhBulkRef          =   React.createRef();
    }

    handleAdd        =      ()     =>   {
        this.setState({add:true})
    }

    handleClose     =      ()     =>    {
        this.setState({add : false})
    }

    itemWhBulkUploadModal   =   (type)  =>  {
        this.itemWhBulkRef.current.itemWhBulkUploadInit(type);
    }

    pageTitleBarJsx                    =    (configTitle)   =>          {
        let title =  ""
        if(configTitle === "quotation"){
           title = "Quotation"
        }else if(configTitle === "deals"){
            title = "Deals"
         }else if(configTitle === "customer_projects"){
            title = "Customer Projects"
         }else{
            title = "Deals"
        }

        return (<Ax><div className="page_title row m0">
            <div className="col-3">
                <h3>Sales Configuration</h3>
            </div>
            <div className={`${(configTitle === "item_configuration") ? 'col-5' : 'col-9'} tap_border_left`}>
                <h3>{title}</h3>
            </div>
           
        </div>
        </Ax>)
    }

    loadConfigContentJsx                   =   (configName)  =>           {
        switch (configName) {
            case 'deals':
                return <DealsConfiguration/>;
            case 'quotation':
                return <QuotationConfiguration/>;
            case 'customer_projects' : 
                return <ProjectConfiguration/>
            default:
                return <DealsConfiguration/>;
        }
    }

    
    //function to get configure table
    configureListTable                     =   (configRow)   =>  {
        return (<div style={{height:"81vh"}} className="bg-white">
            <table className="table table-hover table-bordered bg-white" >
                <tbody className="table-body">
                <tr className={configRow == "deals" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/sales_configuration/deals`} role={"button"} className="link">Deals</Link>
                        </td>
                    </tr> 
                    <tr className={configRow == "customer_projects" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/sales_configuration/customer_projects`} role={"button"} className="link">Customer Projects</Link>
                        </td>
                    </tr> 
                     <tr className={configRow == "quotation" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/sales_configuration/quotation`} role={"button"} className="link">Quotation</Link>
                        </td>
                    </tr>
                  
                   
                </tbody>
            </table>
        </div>)
    }


    render() {
        return <ApplicationLayout>
              <Helmet><title>Sales CRM Configuration</title></Helmet>
            {this.pageTitleBarJsx(this.props.match.params.config)}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl col-3'].join(' ')} >{this.configureListTable(this.props.match.params.config)}</div>
                    <div className="col-9 pl0 pr4">{this.loadConfigContentJsx(this.props.match.params.config)}</div>
            </div>
            </div>
        </ApplicationLayout>
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ConfigurationSales);