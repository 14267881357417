import React from 'react';
import ApplicationLayout from "../../layouts/ApplicationLayout";
import Ax from "../../components/hoc/Ax";
import reportStyles from "./Report.module.css"
import { Link } from "react-router-dom";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { connect } from 'react-redux';
import Loader from '../../components/ui/Loader/Loader';
import { Helmet } from "react-helmet";
import * as actions from "../../store/actions/index"
import ReportSchedulerModal from './reportcomponents/ReportSchedulerModal';
import Reportdata from './Reportdata';

class ReportList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listing_loading: false,
            report_listing: [],
        }

        this.report_url = ORG_API_BASE_URL_2 + '/reports';
    }

    componentDidMount() {
        if (this.props.report_listing && this.props.report_listing.length > 0) {
            let report_list = [];
            let respData = this.props.report_listing.filter((r) => {
                return r.view_access === 'Y'
            });
            respData.forEach(
                r => {
                    if (!report_list[r.section]) {
                        report_list[r.section] = [];
                    }
                    report_list[r.section].push(r);
                }
            )
            this.setState({
                report_listing: report_list,
            });
        } else {
            this.getReportListing()
        }
    }


    //****************************GET REPORT LISTING********************* */
    getReportListing = () => {
        this.setState({ listing_loading: true });
        HttpAPICall.withAthorization('GET', this.report_url, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            respData = respData.filter((r) => {
                return r.view_access === 'Y'
            });

            let report_list = []
            respData?.forEach(
                r => {
                    if (!report_list[r.section]) {
                        report_list[r.section] = [];
                    }
                    report_list[r.section].push(r);
                }
            )
            this.setState({
                report_listing: report_list,
            });
            if (this.props) {
                this.props.reportLisitingInit()
                this.props.reportLisitingLoaded(respData)
            }
        }).then(() => this.setState({ listing_loading: false }));
    }
    // Method to open the modal
    openModal = () => {
        this.setState({ isModalOpen: true });
    }

    // Method to close the modal
    closeModal = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        return (
            <ApplicationLayout>
                <Helmet><title>Report Home</title></Helmet>
                <div className=" page_title m0 row ">
                    <div className='col-sm-12'>
                        <div className='row '>
                            <div className='col-sm-4'>
                                <h3 className='mb-0'>Reports</h3>
                            </div>
                            <div className='col-sm-8'>
                                <div className='text-end d-flex gap-2 align-items-center justify-content-end  mt15'>
                                    <button className="btn btn-secondary  mx-2" onClick={() => this.props.history.push('/report/download_request')}>Download Request
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row mt-1 pl5 pr5'>
                        <div className='col-sm-12 bg-white px-0'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="projectTab">
                                    <button className="nav-link active" id="project_data-tab" type="button"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab_project_data"
                                        aria-controls="tab_project_data"
                                        role="tab"
                                        aria-selected="true"
                                    > Reports </button>
                                </li>
                                {/* <li className="nav-item" role="projectTab">
                                    <button className="nav-link" id="tickets-tab" type="button"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab_tickets"
                                        aria-controls="tab_tickets"
                                        role="tab"
                                        aria-selected="false" > Scheduled Reports
                                    </button>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane show active" id="tab_project_data" aria-labelledby="project_data-tab" >
                                    <div className='tab_content_header'>
                                        {/* <div class="tab_content_wrapper mb-2">
                                            <span class="content_heading">Report Details</span>
                                        </div> */}
                                        <div className="page_containt row">
                                            <div className="col-12 bg-white">
                                                {this.state.listing_loading ? <Loader />
                                                    : Object.entries(this.state.report_listing).map((rs, rsi) => {
                                                        return <Ax key={rsi}>
                                                        <div className="row my-3">
                                                            <div className="col-sm-4">
                                                                <div className="tab_content_wrapper content_heading">{rs[0]}</div>
                                                            </div>
                                                        </div>
                                                            <div className="row mt-2 mb-4">
                                                                {rs[1].map((r, i) => <div className="col-sm-4" key={i}>
                                                                    <div className={[reportStyles.card_container, 'card mb-3'].join(' ')}>
                                                                        <div className={[reportStyles.card_body, 'card-body'].join(' ')}>
                                                                            <div className="row">
                                                                                <Link className={[reportStyles.card_heading, 'lh-base', 'col-sm-12'].join(' ')}
                                                                                    to={{ pathname: "/report/" + r.key }}>
                                                                                    {r.name}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>)}
                                                            </div>
                                                        </Ax>
                                                    })}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="tab-pane " id="tab_tickets" aria-labelledby="tickets-tab">
                                    <div className='tab_content_header p-0'>
                                        <Reportdata /></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        report_listing: state.app && state.app.report_listing ? state.app.report_listing : []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        reportLisitingInit: () => dispatch(actions.reportListingInitilization()),
        reportLisitingLoaded: (data) => dispatch(actions.reportListingDataLoadSuccessfully(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);