import React from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';

class AppBaseComponent extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    tapSelectChange                     =   (selectedOption, form = null, formKey = null, afterCallbackFn=null)  =>  {
        if(form && formKey) {
            let newStateData        =   Array.isArray(selectedOption)
                                        ? {...this.state[form], [formKey] : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []}
                                        : {...this.state[form], [formKey] : selectedOption ? selectedOption.value : ''};
            this.setState({
                [form]             :   newStateData
            }, () => {
                if(afterCallbackFn) {
                    afterCallbackFn();
                }
            });
        }
    }
    
    formInputHandler                    =   (e, key = 'addItemForm',afterCallbackFn = null,getNumber = false)  =>  {
        if(key && e.target && e.target.name) {
            if(e.target && e.target.type == 'number' && getNumber == true) {
                this.setState({ 
                    [key]: { ...this.state[key], [e.target.name] : e.target.value ? Number(e.target.value) : e.target.value } 
                },()    =>  {
                    if(afterCallbackFn) {
                        afterCallbackFn();
                    }
                });
            }else{
                this.setState({
                     [key]: { ...this.state[key], [e.target.name] : e.target.value } 
                    },() => {
                        if(afterCallbackFn) {
                            afterCallbackFn();
                        } 
                    });
            }
            
        }
    }

    formInputStateHandler           =   (val, name, key = 'addForm', afterCallbackFn=null)  =>  {
        if(key && name) {
            this.setState({ [key]: { ...this.state[key], [name] : val } } , () => {
                if(afterCallbackFn) {
                    afterCallbackFn();
                }
            });
        }
    }
    
    /**
     * 
     * @param {string} name
     * @param {string} value
     * @param {string} stateName
     * @param {function} afterCallbackFn
     * @returns Update State
     */
    formDateHandler                    =   (name, value = null, stateName, format ='YYYY-MM-DD', afterCallbackFn = null)  =>  {
    
        if(name, stateName) {
            this.setState({
                [stateName] : {...this.state[stateName], [name] : value ? moment(value).format(format) : null  }
            }, () => {
                if(afterCallbackFn) {
                    afterCallbackFn();
                }
            });
        }
    }

    formDateStateHandler                    =   (value = null, stateName, format ='YYYY-MM-DD', afterCallbackFn = null)  =>  {
        if(stateName) {
            this.setState({
                [stateName] : value ? moment(value).format(format) : null  
            }, () => {
                if(afterCallbackFn) {
                    afterCallbackFn();
                }
            });
        }
    }

    dynamicFormDateHandler                    =   (value = null,name, key= 0, stateName, format ='YYYY-MM-DD', afterCallbackFn = null)  =>  {
        if(name,stateName) {
            let allRows             =           this.state[stateName];
            allRows[key]            =           { ...this.state[stateName][key], [name]: value ? moment(value).format(format) : null };
            this.setState({ [stateName]: allRows }, () => {
                if (afterCallbackFn) {
                    afterCallbackFn();
                }
            });
        }
    }
    
    
    dynamicInputHandlerByKey            =   (e, key = 0, state_key = 'addItemForm',afterCallbackFn,name='',value='')  =>  {
        let allRows                 =   this.state[state_key];
        allRows[key]                =   {...this.state[state_key][key] , [e == null ? name : e.target.name] : e == null ? value : e.target.value};
        this.setState({[state_key] : allRows}, () => {
            if(afterCallbackFn) {
                afterCallbackFn();
            }
        });
    }

    dynamicInputHandlerByKeyVal            =   (e, name, key = 0, state_key = 'addItemForm', afterCallbackFn=null)  =>  {
        let allRows                 =   this.state[state_key];
        allRows[key]                =   {...this.state[state_key][key] , [name] : e};
        this.setState({[state_key] : allRows}, () => {
            if(afterCallbackFn) {
                afterCallbackFn();
            }
        });
    }
    
    dynamicInputHandlerByKeyNameVal     =   (value, name, key = 0, state_key = 'addItemForm', afterCallbackFn = null)  =>  {
        let allRows                 =   this.state[state_key];
        allRows[key]                =   {...this.state[state_key][key] , [name] : value};
        this.setState({[state_key] : allRows}, () => {
            if(afterCallbackFn) {
                afterCallbackFn();
            }
        });
    }
    
    dynamicInputHandlerTwoLevelByKeyNameVal =   (value, name, key1 = 0, key2 = 0, state_key = 'addItemForm', afterCallbackFn = null)  =>  {
       
        let allRows                 =   this.state[state_key][key2];
        allRows[key1]               =   {
            ...this.state[state_key][key2][key1] , 
            [name] : value
        };
        this.setState({
            [state_key]     :   {
                ...this.state[state_key], 
                [key2]      :   allRows
            }
        }, () => {
            if(afterCallbackFn) {
                afterCallbackFn();
            }
        });
    }
    
    dynamicInputDeleteTwoLevelByKeyNameVal =   (key1 = 0, key2 = 0, state_key = 'addItemForm', afterCallbackFn = null)  =>  {
        let allRows                 =   this.state[state_key][key2];
        allRows.splice(key1, 1);
        this.setState({
            [state_key]     :   {
                ...this.state[state_key], 
                [key2]      :   allRows
            }
        }, () => {
            if(afterCallbackFn) {
                afterCallbackFn();
            }
        });
    }
    
    addItemModalInit                    =   ()  =>  {
        if(this.addItemModal) {
            this.addItemModal.show();
        }
        
    }
    
    addItemModalClose                   =   ()  =>  {
        if(this.addItemModal) {
            this.addItemModal.hide();
        }
       
    }

    viewItemModalInit                    =   ()  =>  {
        console.log(this.viewItemModal,'cllcldkdkd')
        if(this.viewItemModal) {
            this.viewItemModal.show();
        }
    }
    
    viewItemModalClose                   =   ()  =>  {
        if(this.viewItemModal) {
            this.viewItemModal.hide();
        }
    }

    addVendorModalClose                   =   ()  =>  {
        if(this.addVendorModal) {
           
            this.addVendorModal.hide();
        }
    }
    
    
    findKey                             =   (object, value) =>  {
        for (let key in object)
            if (object[key] === value) return key;
        return null;
    }
   
}

export default AppBaseComponent;