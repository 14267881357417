import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from "../../../services/HttpAPICall";
import AssetDetails from "./AssetDetails";
import AssetDashboard from "./AssetDashboard";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AssetCard from "../../includes/assetCard/AssetCard";
import tapIcon from "../../../services/TapIcon";
import React from "react";
import AssetCompliance from "./AssetCompliance";
import AssetDocument from "./AssetDocument";
import { Collapse } from "bootstrap";
import Loader from "../../../components/ui/Loader/Loader";
import {
  DisplayListPagination,
  DisplaySearchCriteria,
} from "../../../components/TapUi";
import * as actions from "../../../store/actions/index";
import { Helmet } from "react-helmet";
import AssetHeader from "../includes/AssetHeader";
import AssetRental from "./assetRental/AssetRental";
import AssetHeaderTabs from "./AssetHeaderTabs";
import SubAssembly from "./SubAssembly";
import AdditionalAttributeForm from "../../includes/ui/AdditionalAttributeForm";
import AssetAdditionalAttribute from "./AssetAdditionalAttribute";

class MeasurableAsset extends AppBaseComponent {
  allTabs = ["dashboard", "details"];

  constructor(props) {
    super(props);

    let tab = props.match.params.tab;

    if (!this.allTabs.includes(tab)) {
      //Toaset error Message (No Asset Page FOund) :-
      //Redirecct to Asst List
    }

    this.state = {
      assetDetail: null,
      enc_asset_id: props.match.params.id,
      tabScreen: props.match.params.tab,
      link_load: false,
      searchedElems: null,
      refreshBasicDetails: "N",
    };
  }

  componentDidMount = () => {
    this.getAssetDetail();
    this.initalizeFormData(this.props);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.tab !== this.state.tabScreen) {
      this.setState({
        tabScreen: nextProps.match.params.tab,
        enc_asset_id: nextProps.match.params.id,
      });
      //            this.getAssetDetail()
    }

    if (nextProps.searchedElems !== this.props.searchedElems) {
      this.initalizeFormData(nextProps);
    }
  }

  initalizeFormData = (pr) => {
    if (pr) {
      this.setState({ searchedElems: pr.searchedElems });
    }
  };

  assetListingHandler = (collapse = true) => {
    collapse && new Collapse(document.getElementById("assetListing"));
    // if(document.getElementById('pageTbl')) {
    //     document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
    // }
  };

  linkAssetHandler = (id) => {
    this.props.history.push("/asset/dashboard/" + id);
    this.assetListingHandler();

    this.setState(
      {
        tabScreen: this.state.tabScreen,
        enc_asset_id: id,
        refreshBasicDetails: "Y",
      },
      () => {
        setTimeout(() => {
          this.getAssetDetail();
        }, 1000);
      }
    );
  };

  getAssetDetail = () => {
    HttpAPICall.withAthorization(
      "GET",
      process.env.REACT_APP_IAM_API_SERVER +
        "/asset/" +
        this.state.enc_asset_id,
      this.props.access_token,
      {},
      {},
      (response) => {
        this.setState({
          assetDetail: response.data.data,
          refreshBasicDetails: "N",
        });
      },
      (error) => {
        //Toaset error Message (No Asset FOund) :-
        //Redirecct to Asst List
      }
    );
  };

  loadTabContentJsx = () => {
    const { tabScreen, assetDetail, enc_asset_id, refreshBasicDetails } =
      this.state;

    switch (tabScreen) {
      case "dashboard":
        return (
          <AssetDashboard assetData={assetDetail} asset_enc_id={enc_asset_id} />
        );
      case "details":
        return (
          <AssetDetails
            assetData={assetDetail}
            asset_enc_id={enc_asset_id}
            refreshAssetDetail={this.getAssetDetail}
            refreshData={refreshBasicDetails}
            parentProps={this.props}
          />
        );
      case "compliance":
        return (
          <AssetCompliance
            assetData={assetDetail}
            asset_enc_id={enc_asset_id}
          />
        );
      case "document":
        return (
          <AssetDocument assetData={assetDetail} asset_enc_id={enc_asset_id} />
        );
      case "rental":
        return (
          <AssetRental
            assetData={assetDetail}
            asset_enc_id={enc_asset_id}
            refreshAssetDetail={this.getAssetDetail}
          />
        );
      case "subassembly":
        return (
          <SubAssembly assetData={assetDetail} asset_enc_id={enc_asset_id} />
        );
        case "additionalattribute":
          return (
            <AssetAdditionalAttribute assetData={assetDetail} asset_id={enc_asset_id} />
          );
      default:
        return (
          <AssetDetails
            assetData={assetDetail}
            asset_enc_id={enc_asset_id}
            refreshAssetDetail={this.getAssetDetail}
            refreshData={refreshBasicDetails}
            parentProps={this.props}
          />
        );
    }
  };

  pageTitleBarJsx = () => {
    let obj = {
      search_asset_id: this.state?.assetDetail?.asset_id,
      asset_name: `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`,
    };
    return (
      <div className="page_title row m0">
        <div className="col-12 asset_collapse">
          <button
            type="button"
            onClick={this.assetListingHandler}
            className="btn btn-primary asset_collapse_button"
            style={{ height: "73%" }}
          >
            <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretRight} />
          </button>
          <h3 style={{ marginLeft: "36px" }}>
            {this.state.assetDetail ? this.state.assetDetail.name : ""}
          </h3>
          <AssetHeader
            tabScreen={this.state.tabScreen}
            parentProps={this.props}
            assetDetail={this.state.assetDetail}
            afterChangeStatus={() => {
              this.getAssetDetail();
              this.setState({ refreshBasicDetails: "Y" });
            }}
          />
        </div>
      </div>
    );
  };

  assetListingJsx = () => {
    return (
      <div id="assetListing" className="bg-white collapse">
        <div className="page_title row m0">
          <div className="col-12 asset_collapse mr0 pr0">
            <h3>Asset List</h3>
            <div className="float-end">
              <button
                type="button"
                onClick={this.assetListingHandler}
                className="btn btn-primary"
                style={{
                  height: "71%",
                  lineHeight: "26px",
                  maxHeight: "90%!important",
                  marginTop: "10px",
                }}
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretLeft} />
              </button>
            </div>
          </div>
        </div>
        <div className="page_containt row" style={{ height: "81vh" }}>
          <div className="pageTbl col-12" style={{ paddingRight: "7px" }}>
            {this.state.assetDetail &&
            this.state.assetDetail.cl_attribute &&
            this.state.assetDetail.cl_attribute == "Y" ? (
              <Ax>
                <DisplaySearchCriteria
                  searchedElems={this.props.searchedElems}
                />
                <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                  <thead className="table-secondary">
                    <tr>
                      <th scope="col" style={{ width: "15%" }}>
                        Asset
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.asset_listing_loaded ? (
                      <tr>
                        <td className="text-center" colSpan="9">
                          <Loader />
                        </td>
                      </tr>
                    ) : this.props.asset_listing.length > 0 ? (
                      this.props.asset_listing.map((item, index) =>
                        this.listingTableTrJsx(item, index)
                      )
                    ) : (
                      this.listingTableTrJsx(null)
                    )}
                  </tbody>
                </table>
                <DisplayListPagination
                  meta={this.props.asset_meta}
                  onPageChange={(e) =>
                    this.props.onListingSuccess(
                      e.selected + 1,
                      this.props.asset_param,
                      "Y",
                      this.props.searchedElems
                    )
                  }
                />
              </Ax>
            ) : (
              <Ax>
                <DisplaySearchCriteria
                  searchedElems={this.props.non_searchedElems}
                />
                <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                  <thead className="table-secondary">
                    <tr>
                      <th scope="col" style={{ width: "15%" }}>
                        Asset
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.non_asset_listing_loaded ? (
                      <tr>
                        <td className="text-center" colSpan="9">
                          <Loader />
                        </td>
                      </tr>
                    ) : this.props.non_asset_listing.length > 0 ? (
                      this.props.non_asset_listing.map((item, index) =>
                        this.listingTableTrJsx(item, index)
                      )
                    ) : (
                      this.listingTableTrJsx(null)
                    )}
                  </tbody>
                </table>
                <DisplayListPagination
                  meta={this.props.non_asset_meta}
                  onPageChange={(e) =>
                    this.props.onNonMeasurableListingSuccess(
                      e.selected + 1,
                      this.props.non_asset_param,
                      "N",
                      this.props.non_searchedElems
                    )
                  }
                />
              </Ax>
            )}
          </div>
        </div>
      </div>
    );
  };

  listingTableTrJsx = (item, index = 0) => {
    return item ? (
      <tr key={index}>
        <td>
          <a
            onClick={() => this.linkAssetHandler(item.asset_id)}
            role="button"
            className="link-primary"
          >
            {item.name}
          </a>{" "}
          <br />
          <small>Code : {item.asset_code ?? "-"}</small>
          <br />
          <small>Asset Type : {item.asset_type_name ?? "-"}</small>
          <br />
          <small>Site Name : {item.site ?? "-"}</small>
        </td>
      </tr>
    ) : (
      <tr key={index}>
        <td className="text-center" colSpan={9}>
          No Record Found
        </td>
      </tr>
    );
  };

  render = () => {
    const { enc_asset_id } = this.state;
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Asset Detail</title>
        </Helmet>
        <div className="asset_collapse">
          {this.pageTitleBarJsx()}
          <div className="asset_table">{this.assetListingJsx()}</div>
          <div className="container-fluid pl5">
            <div className=" row mt-1">
              <div className="col-9 ">
                <div className="row ml1">
                  <div className="col-12 pl0 bg-white ">
                    <AssetHeaderTabs
                      id={enc_asset_id}
                      activeTab={this.state.tabScreen}
                      permissions={this.props.permissions}
                    />
                    {this.loadTabContentJsx()}
                  </div>
                </div>
              </div>
              <div
                className="page_containt col-3 pl4 pr4"
                style={{ top: "0px" }}
              >
                <div className="pageTbl">
                  <AssetCard
                    assetCardDetails={this.state.assetDetail}
                    refreshAssetDetail={() => {
                      this.getAssetDetail();
                      this.setState({ refreshBasicDetails: "Y" });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    asset_listing_loaded:
      state.app && state.app.asset_listing_loaded
        ? state.app.asset_listing_loaded
        : false,
    asset_listing:
      state.app && state.app.asset_listing ? state.app.asset_listing : [],
    asset_meta: state.app && state.app.asset_meta ? state.app.asset_meta : null,
    asset_total_count:
      state.app && state.app.asset_total_count
        ? state.app.asset_total_count
        : 0,
    asset_param:
      state.app && state.app.asset_search_param
        ? state.app.asset_search_param
        : null,
    searchedElems:
      state.app && state.app.formSearchedElems
        ? state.app.formSearchedElems
        : [],
    permissions: state.app && state.app.acl_info ? state.app.acl_info : null,
    non_searchedElems:
      state.app && state.app.non_measurable_formSearchedElems
        ? state.app.non_measurable_formSearchedElems
        : [],
    non_asset_param:
      state.app && state.app.non_measurable_asset_search_param
        ? state.app.non_measurable_asset_search_param
        : {},
    non_asset_listing_loaded:
      state.app && state.app.non_measurable_asset_listing_loaded
        ? state.app.non_measurable_asset_listing_loaded
        : false,
    non_asset_listing:
      state.app && state.app.non_measurable_asset_listing
        ? state.app.non_measurable_asset_listing
        : [],
    non_asset_meta:
      state.app && state.app.non_measurable_asset_meta
        ? state.app.non_measurable_asset_meta
        : null,
    non_asset_total_count:
      state.app && state.app.non_measurable_asset_total_count
        ? state.app.non_measurable_asset_total_count
        : 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onListingLoding: (data) =>
      dispatch(actions.assetListingDataInitilization()),
    onListingSuccess: (page, searchParm, cl_attribute, formSearchedElems) =>
      dispatch(
        actions.assetListingDataLoading(
          page,
          searchParm,
          cl_attribute,
          formSearchedElems
        )
      ),
    onNonMeasurableListingSuccess: (
      page,
      searchParm,
      cl_attribute,
      formSearchedElems
    ) =>
      dispatch(
        actions.nonMeasurableAssetListingDataLoading(
          page,
          searchParm,
          cl_attribute,
          formSearchedElems
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurableAsset);
